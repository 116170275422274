import Axios from "axios";
import React, { Fragment, useEffect, useState, useRef } from "react";
import proteccion from "../pdf/politicaproteccion.pdf";
import "./../styles/claims.css";

const ClaimsForm = ({ t }) => {
  const [messageSuccess, setMessageSuccess] = useState("");
  const [messageError, setMessageError] = useState("");
  const [agree, setAgree] = useState(false);
  const [send, setSend] = useState({
    condition: "",
    clienteDirecto: "",
    ruc: "",
    razonSocial: "",
    nombre: "",
    documento: "",
    telefono: "",
    email: "",
    apoderado: "",
    monto: "",
    tipobien: "",
    description: "",
    tiporeclamo: "",
    nropedido: "",
    codigo: "",
    job: "",
    detalle: "",
  });

  const handleChange = (e) => {
    const { name, type, value, checked } = e.target;

    setSend((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const boxesRef = useRef([]);
  const nextButtonsRef = useRef([]);
  const previousButtonsRef = useRef([]);

  const handleNext = (index) => {
    if (boxesRef.current[index]) {
      boxesRef.current[index].style.animation = "fadeOut 1s";
      setTimeout(() => {
        boxesRef.current[index].style.display = "none";
        if (boxesRef.current[index + 1]) {
          boxesRef.current[index + 1].style.display = "grid";
        }
      }, 1000);
    }
  };

  const handlePrevious = (index) => {
    if (boxesRef.current[index + 1]) {
      boxesRef.current[index + 1].style.animation = "fadeOut 1s";
      setTimeout(() => {
        boxesRef.current[index + 1].style.display = "none";
        if (boxesRef.current[index]) {
          boxesRef.current[index].style.display = "grid";
        }
      }, 1000);
    }
  };

  useEffect(() => {
    nextButtonsRef.current.forEach((btn, index) => {
      btn.addEventListener("click", () => handleNext(index));
    });

    previousButtonsRef.current.forEach((btn, index) => {
      btn.addEventListener("click", () => handlePrevious(index));
    });

    return () => {
      nextButtonsRef.current.forEach((btn, index) =>
        btn.removeEventListener("click", () => handleNext(index))
      );
      previousButtonsRef.current.forEach((btn, index) =>
        btn.removeEventListener("click", () => handlePrevious(index))
      );
    };
  }, []);

  const messageFade = (message) => {
    setMessageError(message);
    setTimeout(() => setMessageError(""), 2000);
  };

  const validateFields = (fields, message) => {
    for (let field of fields) {
      const inputElement = document.getElementById(field);
      if (!inputElement) {
        console.error(`Elemento con id "${field}" no encontrado.`);
        continue; // Evita que falle y sigue con el siguiente campo
      }
      if (!inputElement.value.trim()) {
        setMessageError(message);
        return false; // Indica que hay un error
      }
    }
    return true; // Todo está bien
  };

  const listClienteDirecto = ["Si", "No"];

  const validateFirst = () => {
    if (!send.condition) {
      messageFade("Debe aceptar la política de protección de datos");
      return;
    }
    if (!listClienteDirecto.includes(send.clienteDirecto)) {
      messageFade("Debe seleccionar si es un cliente directo o no");
      return;
    }

    if (
      !validateFields(
        ["nombre", "documento", "email"],
        "Todos los campos son obligatorios"
      )
    ) {
      return;
    }

    handleNext(0);
  };

  const validateSecond = () => {
    if (
      !validateFields(
        ["description", "monto", "tipobien"],
        "Todos los campos son obligatorios"
      )
    ) {
      return false;
    }
    handleNext(1);
  };

  const token = localStorage.getItem("token");
  const config = { headers: { "x-access-token": token } };

  const sendData = async () => {
    if (
      !validateFields(
        ["nropedido", "codigo", "job", "detalle", "tiporeclamo"],
        "Te faltan campos por llenar"
      )
    ) {
      return;
    }

    try {
      await Axios.post(
        "https://ws.pamolsa.com.pe/api/book/send?form=claims",
        send,
        config
      );

      ["nropedido", "codigo", "job", "detalle", "tiporeclamo"].forEach((id) => {
        const input = document.getElementById(id);
        if (input) input.value = "";
      });
      setMessageError("");
      setMessageSuccess(
        "Gracias por enviarnos su reclamo, lo contactaremos lo más pronto posible."
      );
    } catch (error) {
      console.error("Error en sendData:", error);

      const errorMessage =
        error.response?.data?.message ||
        "Error interno. Por favor, inténtalo de nuevo más tarde.";

      setMessageError(`Error al enviar el formulario: ${errorMessage}`);
    }
  };

  return (
    <section className="claims-form">
      <div className="claims-content__form">
        <div className="img-content__form">
          <img src={require("../images/img/claimsform.jpeg")} alt="" />
        </div>
        <div className="form-content">
          {messageSuccess === "" ? (
            <Fragment>
              <div className="box" ref={(el) => (boxesRef.current[0] = el)}>
                <h6>
                  Cuéntenos su caso para escalarlo con servicio al cliente.
                </h6>
                <p>1) Identificación del consumidor reclamante</p>
                <div className="conditions">
                  <label>
                  <input
                      type="checkbox"
                      id="condition"
                      name="condition"
                      onChange={handleChange}
                      checked={send.condition}
                      style={{ margin: "5px" }}
                    />
                    {t("form.privacidad")}{" "}
                    <a
                      style={{
                        textDecoration: "none",
                        color: "rgb(0 159 227)",
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={proteccion}
                    >
                      {t("form.privacidad_1")}
                    </a>{" "}
                    {t("form.privacidad_2")}
                  </label>
                </div>
                <form>
                  <div>
                    <h6
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "#808291",
                        marginBottom: "5px",
                      }}
                    >
                      Es usted un cliente directo:
                    </h6>
                    <div
                      style={{
                        marginBottom: "15px",
                      }}
                    >
                      {listClienteDirecto.map((item, index) => (
                        <label
                          key={index}
                          style={{
                            maxWidth: "100%",
                            margin: "5px",
                            color: "#808291",
                            fontSize: "1rem",
                          }}
                        >
                          <input
                            type="radio"
                            id="clienteDirecto"
                            name="clienteDirecto"
                            onChange={handleChange}
                            value={item}
                            checked={send.clienteDirecto === item}
                            style={{
                              margin: "5px",
                            }}
                            required
                          />
                          {item}
                        </label>
                      ))}
                    </div>
                  </div>
                  <div className="inputBox"></div>

                  <div className="inputBox">
                    <label>RUC</label>
                    <input
                      type="text"
                      id="ruc"
                      name="ruc"
                      onChange={handleChange}
                      value={send.ruc}
                      required
                    />
                  </div>
                  <div className="inputBox">
                    <label>Razón Social</label>
                    <input
                      type="text"
                      id="razonSocial"
                      name="razonSocial"
                      onChange={handleChange}
                      value={send.razonSocial}
                      required
                    />
                  </div>
                  <div className="inputBox">
                    <label>Nombre</label>
                    <input
                      type="text"
                      id="nombre"
                      name="nombre"
                      value={send.nombre}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="inputBox">
                    <label>DNI/CE:</label>
                    <input
                      type="text"
                      id="documento"
                      name="documento"
                      value={send.documento}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="inputBox">
                    <label>{t("form.telefono")}:</label>
                    <input
                      type="text"
                      name="telefono"
                      onChange={handleChange}
                      value={send.telefono}
                      required
                    />
                  </div>
                  <div className="inputBox">
                    <label>Email:</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={send.email}
                      required
                    />
                  </div>
                  <div className="inputBox large">
                    <label>
                      Padre o Madre: *(Para el caso de menores de edad)
                    </label>
                    <select
                      name="apoderado"
                      onChange={handleChange}
                      required
                      value={send.apoderado}
                    >
                      <option value="N/A">-</option>
                      <option value="Padre">Padre</option>
                      <option value="Madre">Madre</option>
                    </select>
                  </div>
                </form>
                {messageError && (
                  <div className="messageError">{messageError}</div>
                )}
                <div className="buttonSection">
                  <button
                    onClick={() => validateFirst()}
                    className="nextButton"
                  >
                    {t("productos.siguiente")}
                  </button>
                </div>
              </div>
              <div className="box" ref={(el) => (boxesRef.current[1] = el)}>
                <h6>
                  Cuéntenos su caso para escalarlo con servicio al cliente.
                </h6>
                <p>2) Identificación del bien contratado</p>
                <form>
                  <div className="inputBox">
                    <label>Monto reclamado:</label>
                    <input
                      type="text"
                      id="monto"
                      name="monto"
                      value={send.monto}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="inputBox">
                    <label>Tipo de bien:</label>
                    <select
                      id="tipobien"
                      name="tipobien"
                      onChange={handleChange}
                      value={send.tipobien}
                      required
                    >
                      <option value="">Seleccione</option>
                      <option value="Producto">Producto</option>
                      <option value="Servicio">Servicio</option>
                    </select>
                  </div>
                  <div className="inputBox large">
                    <label>Descripción:</label>
                    <textarea
                      id="description"
                      name="description"
                      onChange={handleChange}
                      value={send.description}
                      require
                      rows={3}
                    />
                  </div>
                </form>
                {messageError && (
                  <div className="messageError">{messageError}</div>
                )}
                <div className="buttonSection">
                  <button
                    className="previousButton"
                    onClick={() => handlePrevious(0)}
                  >
                    Anterior
                  </button>
                  <button onClick={validateSecond} className="nextButton">
                    {t("productos.siguiente")}
                  </button>
                </div>
              </div>
              <div className="box" ref={(el) => (boxesRef.current[2] = el)}>
                <h6>
                  Cuéntenos su caso para escalarlo con servicio al cliente.
                </h6>
                <p>3) Detalle de reclamación</p>
                <form>
                  <div className="inputBox">
                    <label>Tipo de reclamación:</label>
                    <select
                      id="tiporeclamo"
                      name="tiporeclamo"
                      onChange={handleChange}
                      required
                    >
                      <option value="">Seleccione</option>
                      <option value="Reclamo">Reclamo</option>
                      <option value="Queja">Queja</option>
                    </select>
                  </div>
                  <div className="inputBox">
                    <label>N° pedido:</label>
                    <input
                      type="text"
                      id="nropedido"
                      name="nropedido"
                      onChange={handleChange}
                      value={send.nropedido}
                      required
                    />
                  </div>
                  <div className="inputBox">
                    <label>Código y descripción del producto:</label>
                    <input
                      type="text"
                      id="codigo"
                      name="codigo"
                      onChange={handleChange}
                      value={send.codigo}
                      required
                    />
                  </div>
                  <div className="inputBox">
                    <label>#JOB:</label>
                    <input
                      type="text"
                      id="job"
                      name="job"
                      value={send.job}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="inputBox large">
                    <label>Detalle:</label>
                    <textarea
                      type="text"
                      id="detalle"
                      name="detalle"
                      value={send.detalle}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </form>
                {messageError && (
                  <div className="messageError">{messageError}</div>
                )}
                <div className="buttonSection">
                  <button
                    className="previousButton"
                    onClick={() => handlePrevious(1)}
                  >
                    Anterior
                  </button>
                  <button onClick={sendData} className="nextButton">
                    {t("form.enviar")}
                  </button>
                </div>
              </div>
            </Fragment>
          ) : (
            <div className="messageSuccess">{messageSuccess}</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ClaimsForm;
